import { Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fontContainer: {
    display: 'flex',
  },
}));

function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.fontContainer}
    >
      <Link
        color="inherit"
        href="https://stjohn.org.au/course_resources/SJAA%20-%20copyright%20-%20third%20party.pdf"
        target="_blank"
        rel="noopener"
      >
        Copyright
      </Link>
      {' © 2022 St John Ambulance Australia Ltd.'}
    </Typography>
  );
}

export default Copyright;
