import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Layout from './components/Layout';
import Cookies from 'universal-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
function App() {
  const cookies = new Cookies();
  const registed = cookies.get('registed');
  const state = cookies.get('state');

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>{registed ? <SignIn state={state} /> : <SignUp />}</Layout>
    </QueryClientProvider>
  );
}

export default App;
