import { Grid, Paper } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import backgroundIMG from '../img/banner.jpg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import theme from '../theme';
import InAppBroswerDetector from './InAppBroswerDetector';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url("${backgroundIMG}")`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  },
  login: {
    height: '100vh',
  },
}));

interface LayoutProps {
  children: JSX.Element;
}

function Layout({ children }: LayoutProps) {
  const classes = useStyles();

  //Detect the screen size, if the screen size is larger than lg, container will be full 100vh
  const matche = useMediaQuery(useTheme().breakpoints.up('lg'));

  return (
    <ThemeProvider theme={theme}>
      <InAppBroswerDetector />
      <Grid
        container
        component="main"
        className={matche ? classes.root : undefined}
      >
        <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>
        <Grid
          container
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          alignItems="center"
          justifyContent="center"
          direction="column"
          className={matche ? classes.login : undefined}
        >
          {children}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Layout;
