import Joi from 'joi';
export const schemaMap: {
  [key: string]: Joi.StringSchema | Joi.BooleanSchema;
} = {
  firstName: Joi.string()
    .required()
    .pattern(/^[a-zA-Z]+$/)
    .message('Please use alpha characters only [a-z].')
    .label('First name'),
  lastName: Joi.string()
    .required()
    .pattern(/^[a-zA-Z]+-?[a-zA-Z]+$/) // allow hyphenated last names like "O-Neil"
    .message(
      'Please use alpha characters only [a-z]. This field does not support hyphens used as a prefix or suffix.',
    )
    .label('Last name'),
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .label('Email'),
  state: Joi.string().required().label('State'),
  emailForFutureProducts: Joi.boolean().label('Email for future productions'),
  emailForFutureFirstAidProtocols: Joi.boolean().label(
    'Email for future first aid protocols',
  ),
  agreePolicy: Joi.boolean().label('agreePolicy'),
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
