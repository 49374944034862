import {
  Grid,
  Avatar,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import React, { useState } from 'react';
import { schemaMap } from './util';
import Input from '../components/Input';
import Joi from 'joi';
import axios, { AxiosError } from 'axios';

import logo from '../img/DFA-LOGO.png';
import Copyright from '../components/Copyright';
import { useMutation } from 'react-query';

const states = [
  {
    value: '',
    label: '',
  },
  {
    value: 'ACT',
    label: 'ACT',
  },
  {
    value: 'NSW',
    label: 'NSW',
  },
  {
    value: 'QLD',
    label: 'QLD',
  },
  {
    value: 'TAS',
    label: 'TAS',
  },
  {
    value: 'VIC',
    label: 'VIC',
  },
  {
    value: 'SA',
    label: 'SA',
  },
  {
    value: 'NT',
    label: 'NT',
  },
  {
    value: 'WA',
    label: 'WA',
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: 'auto',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  descrition: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  asterisk: {
    color: theme.palette.primary.main,
    display: 'inline-block',
  },
  policyError: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}));

const initialFvalue = {
  firstName: '',
  lastName: '',
  email: '',
  state: '',
  emailForFutureProducts: false,
  emailForFutureFirstAidProtocols: false,
  agreePolicy: false,
};

interface SignUpProps {}

function SignUp(props: SignUpProps) {
  const [errors, setErrors] = useState<
    | {
        [key: string]: string;
      }
    | undefined
  >({});
  const [signUpinfo, setSignUpinfo] = useState(initialFvalue);

  const classes = useStyles();

  const PrivacyPolicy = (
    <div>
      <div className={classes.asterisk}>(*)</div> I agree that my personal data
      will be used to support my experience throughout this website, and for
      other purposes described in St John Ambulance Australia's{' '}
      <Link href="https://stjohn.org.au/privacy-policy" color="secondary">
        privacy policy.
      </Link>
    </div>
  );

  const postStudent = async (signUpinfo: any) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const apikey = process.env.REACT_APP_API_KEY;
    return axios.post(`${baseUrl}/nat-driver-first-aid/students`, signUpinfo, {
      params: { apikey },
    });
  };

  const postStudentMutation = useMutation(postStudent, {
    onSuccess: (data) => {
      registeredLocally();
      if (signUpinfo.state === 'WA') {
        window.open('https://clicktosave.com.au', '_self');
      } else {
        window.open('learn/story.html', '_self');
      }
    },
    onError: (error: AxiosError) => {
      if (error.code === 'ERR_NETWORK') {
        alert('Network error. Please try again later.');
      }
    },
  });

  const handleInputChange = ({
    target: input,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = input;
    setSignUpinfo({ ...signUpinfo, [name]: value });

    const _errors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) {
      _errors[name] = errorMessage;
      setErrors(_errors);
    } else {
      delete _errors[name];
      setErrors(_errors);
    }
  };

  const handleCheckboxChange = ({
    target: Checkbox,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = Checkbox;
    setSignUpinfo({ ...signUpinfo, [name]: checked });
  };

  const handleBlur = ({
    target: input,
  }: React.FocusEvent<HTMLInputElement>) => {
    const { name } = input;
    const _errors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) {
      _errors[name] = errorMessage;
      setErrors(_errors);
    } else {
      delete _errors[name];
      setErrors(_errors);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    postStudentMutation.mutate(signUpinfo);
  };

  const registeredLocally = () => {
    /**
     * In this case we set cookie for just one day
     * Cookie will be deleted after it expires
     * https://superuser.com/questions/356265/what-do-browsers-do-with-expired-cookies
     */
    const cookies = new Cookies();
    cookies.set('registed', 'true', {
      path: '/',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
    cookies.set('state', signUpinfo.state, {
      path: '/',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
  };

  const validate = () => {
    const options = { abortEarly: false };
    const localSchema = Joi.object(schemaMap);
    const { error } = localSchema.validate(signUpinfo, options);

    if (!error) return {};
    const errors: {
      [key: string]: string;
    } = {};

    for (let item of error.details)
      errors[item.path[0] as string] = item.message;
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    const localSchema = schemaMap[name];
    const { error } = localSchema.validate(value);
    return error ? error.message : null;
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={logo} variant="square" />
      <Typography variant="h4" align="center" className={classes.title}>
        Sign up to access your Driver First Aid course
      </Typography>
      <Typography variant="body2" align="center" className={classes.descrition}>
        Driver First Aid is a free St John Ambulance Australia online awareness
        course, which aims to instil lifesaving first aid skills into young
        drivers and their passengers.
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              name="firstName"
              label="First Name"
              value={signUpinfo.firstName}
              handleInputChange={handleInputChange}
              errors={errors}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="lastName"
              label="Last Name"
              value={signUpinfo.lastName}
              handleInputChange={handleInputChange}
              errors={errors}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="email"
              label="Email Address"
              value={signUpinfo.email}
              handleInputChange={handleInputChange}
              errors={errors}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="state"
              name="state"
              variant="outlined"
              fullWidth
              label="State"
              value={signUpinfo.state}
              onChange={handleInputChange}
              select
              onBlur={handleBlur}
              SelectProps={{
                native: true,
                displayEmpty: true,
              }}
              {...(errors &&
                errors.state && {
                  error: true,
                  helperText: errors.state,
                })}
            >
              {states.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={signUpinfo.emailForFutureProducts}
                  name="emailForFutureProducts"
                />
              }
              label="Email me about all things St John (Products, Training, Volunteering)"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={signUpinfo.emailForFutureFirstAidProtocols}
                  name="emailForFutureFirstAidProtocols"
                />
              }
              label="Email me about changes to First Aid Protocols"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={signUpinfo.agreePolicy}
                  name="agreePolicy"
                />
              }
              label={PrivacyPolicy}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
          disabled={
            (errors && (Object.keys(errors).length === 0 ? false : true)) ||
            postStudentMutation.isLoading ||
            signUpinfo.agreePolicy === false
          }
        >
          Sign Up
        </Button>
        {signUpinfo.agreePolicy ? null : (
          <Grid item xs={12}>
            <p className={classes.policyError}>
              You must agree to our Privacy Policy above to proceed with Sign
              Up.
            </p>
          </Grid>
        )}
        <Grid container justifyContent="flex-end"></Grid>
      </form>
      <Copyright />
    </div>
  );
}

export default SignUp;
