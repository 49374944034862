import { TextField } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  value: string;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  errors: { [key: string]: string } | undefined;
  name: string;
  label: string;
  autoFocus?: boolean;
  select?: boolean;
  children?: ReactElement[];
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FunctionComponent<Props> = ({
  value,
  handleInputChange,
  errors,
  name,
  label,
  autoFocus = false,
  children,
  select,
  handleBlur,
}) => {
  return (
    <TextField
      name={name}
      variant="outlined"
      fullWidth
      id={name}
      label={label}
      value={value}
      onChange={handleInputChange}
      autoFocus={autoFocus}
      select={select}
      onBlur={handleBlur}
      {...(errors &&
        errors[name] && {
          error: true,
          helperText: errors[name],
        })}
    >
      {children}
    </TextField>
  );
};

export default Input;
