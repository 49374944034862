const SignIn = ({ state }: { state: string }) => {
  if (state === 'WA') {
    window.open('https://clicktosave.com.au', '_self');
  } else {
    window.open('learn/story.html', '_self');
  }

  return <></>;
};

export default SignIn;
