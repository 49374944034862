import { Box, makeStyles, Modal, Typography } from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  mesageBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
  },

  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function InAppBroswerDetector() {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(!isPrintCertificateSupported());
  // const [open, setOpen] = useState(true);

  /**
   * Facebook in-app browser and Instagram in-app browser does not support printing
   * So if we detect the user is using Facebook in-app browser or Instagram in-app browser
   * We will show a modal to tell the user to open the link in a browser
   */
  function isPrintCertificateSupported() {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent;
      // Check for FBAN/FBAV in user agent
      if (
        userAgent.indexOf('FBAN') > -1 ||
        userAgent.indexOf('FBAV') > -1 ||
        userAgent.indexOf('Instagram') > -1
      ) {
        return false;
      }
    }
    return true;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.mesageBox}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Attention
        </Typography>
        <Typography id="modal-modal-description" className={classes.message}>
          We have detected that you are using a Facebook or Instagram in-app
          browser. Unfortunately, Facebook and Instagram in-app browsers do not
          support printing of certificates.
        </Typography>
        <Typography id="modal-modal-description" className={classes.message}>
          1. Please close this browser.
        </Typography>
        <Typography id="modal-modal-description" className={classes.message}>
          2. Visit <strong>driverfirstaid.org.au</strong> from your standard web
          browser.
        </Typography>
        <Typography id="modal-modal-description">
          <i>Safari or Google Chrome</i>
        </Typography>
      </Box>
    </Modal>
  );
}

export default InAppBroswerDetector;
